/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 18:46:07
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-20 18:54:44
 */
import Expandpackage from './expandpackage.vue'
export default Expandpackage
