/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-20 18:46:46
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-20 18:51:08
 */

export default {
  name: 'Expandpackage'
}
